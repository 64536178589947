.editProduct {
  height: 1140px;
  margin-left: 230px;
  background-color: #f4f3f8;
  padding-top: 0px;
}

.div_main_principal_tipo {
  width: 1170px;
  margin-left: 20px;
  border-radius: 4px;
  margin: auto;
}

.editProduct_div_button {
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
}

.editProduct_div1 {
  width: 230px;
  height: 420px;
  margin-left: 20px;
  float: left;
}

.editProduct_div3 {
  width: 660px;
  height: 150px;
  margin-top: 20px;
  float: left;
  margin-left: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.editProduct_div3 p {
  margin-left: 20px;
  margin-bottom: 9px;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Source Code Pro";
}

.editProduct_div3_main {
  height: 150px;
  width: 250px;
  float: left;
  margin-left: 20px;
}

.editProduct_div3_submain {
  height: 89%;
  width: 170px;
  float: left;
}

.editProduct_div3_main img {
  margin-top: 20px;
  height: 180px;
  width: 180px;
  border-radius: 4px;
  float: left;
}

.editProduct_div3_submain_img1 {
  margin-top: 20px;
  height: 87px;
  width: 87px;
  border-radius: 4px;
}

.editProduct_div3_submain_img2 {
  margin-top: 10px;
  height: 87px;
  width: 87px;
  border-radius: 4px;
}

.editProduct_div3_submain_img3 {
  margin-top: 10px;
  height: 87px;
  width: 87px;
  border-radius: 4px;
}

/* Name */

.editProduct_name {
  width: 252px;
  height: 85px;
  background-color: white;
  overflow: auto;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.editProduct_name_specialTAGS {
  width: 252px;
  background-color: white;
  overflow: auto;
  min-height: 85px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.editProduct_name_specialTAGS p {
  margin-left: 7%;
  margin-bottom: 9px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Source Code Pro";
}

.editProduct_name p {
  margin-left: 7%;
  margin-bottom: 9px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Source Code Pro";
}

.editProduct_quantitys {
  width: 1140px;
  height: 283px;
  background-color: white;
  margin-bottom: 30px;
  float: left;
  margin-left: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.EDITadminAddProduct-div {
  width: 900px;
  height: 750px;
  margin: auto;
  margin-top: 30px;
}

.EDITadminAddProduct-div-div {
  float: left;
  width: 50%;
  height: 100%;
}

.EDITadminAddProduct-div-div-p {
  font-size: 14px;
}

.EDITadminAddProduct-div-div h2 {
  font-size: 20px;
}

.EDITadminAddProduct-div-div input {
  width: 90%;
  height: 19px;
  border: 1px solid rgb(227, 219, 219);
}

.EDITadminAddProduct-div-div textarea {
  width: 90%;
  height: 59px;
  border: 0.5px solid #b1acac;
  font-family: "Source Code Pro";
}

.EDITadminAddProduct-div-div-DELETE {
  width: 30%;
  height: 30px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  background-color: red;
  margin-top: 13px;
  margin-left: 275px;
}

.EDITadminAddProduct-div-div-EDIT {
  width: 30%;
  height: 30px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  background-color: #d5cbc4;
  margin-top: 13px;
  margin-left: 275px;
}

.EDITadminAddProduct-div-div-EDIT:hover {
  background-color: #ebdfd6;
}

.EDITadminAddProduct-div-div-DELETE:hover {
  background-color: rgb(252, 88, 88);
}

.EDITdiv-formSize {
  width: 100%;
  height: 70%;
  margin-top: 20px;
}

.EDITdiv-formSize-middle {
  width: 50%;
  height: 100%;
  float: left;
}

.EDITdiv-formSize-middle-divHeader {
  width: 90%;
  height: 50px;
  margin: 0;
}

.EDITdiv-formSize-middle-divHeader-cell {
  width: 50%;
  height: 100%;
  float: left;
}

.EDITdiv-formSize-middle-divHeader-cell p {
  margin: 0;
}

.EDITdiv-formSize-middle-divData {
  width: 90%;
  height: 30px;
  margin: 0;
}

.EDITdiv-formSize-middle-divData-cell {
  width: 50%;
  height: 100%;
  float: left;
}

.EDITdiv-formSize-middle-divData-cell p {
  margin: 0;
}

.EDITdiv-formSize-middle-divData-cell input {
  width: 70%;
}

.admin_qtySizeData_div_horizontalScrollbar {
  height: 240px;
  float: left;
  overflow-x: scroll;
  white-space: nowrap;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.admin_available_checkbuttom {
  height: 15px;
  width: 15px;
  background-color: #2ed47a;
  margin-left: 19px;
  margin-top: 15px;
  border-radius: 3px;
}

.admin_available_checkbuttom:hover {
  cursor: pointer;
}

.admin_available_checkbuttom_false {
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 2px solid red;
  margin-left: 19px;
  margin-top: 15px;
}

.admin_available_checkbuttom_false:hover {
  cursor: pointer;
}

.edit_activeDiv {
  width: 145px;
  height: 20px;
  float: right;
  font-weight: 600;
  font-size: 17px;
}

.edit_activeDiv:hover {
  cursor: pointer;
}

.edit_activeDiv p {
  display: inline;
}

.edit_activeIcon {
  width: 14px;
  height: 14px;
  border-radius: 19px;
  background-color: #2ed47a;
  float: left;
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 13px;
}

.edit_activeIcon2 {
  width: 14px;
  height: 14px;
  border-radius: 19px;
  background-color: #f41616;
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}
