/* Irrelevante */
.div-login_footer{
    width: 100%;
    height: 35vh;
    background-color: #fdfcff;    
    position: fixed;
    bottom: 0;
    left: 0; /* Add this to make sure it's aligned to the left */
    box-shadow: 0px 0px 8px 2px rgb(222, 222, 222);
}

.div_footer_information{
    width: 100%;
    height: 70%;
    overflow: hidden;
}

.div-footer-email{
    height: 100%;
    width: 43%;
    float: left;
    margin-top: 40px;
}

.div-footer-email-margin{
    width: 330px;
    height: 150px;
    margin: auto;
}

.div-footer-email-divInput{
    float: left;
    width: 180px;
    height: 40px;
}

.div-footer-email-divButton{
    float: left;
    width: 120px;
    height: 40px;
}

.div-footer-email-input{
    width: 179px;
    max-width: 179px;
    height: 32px;
    margin: 0;
    outline: none;
    border: none;
    font-family: 'Source Code Pro';
}

.div-footer-email-button{
    width: 120px;
    height: 34px;
    border-radius: 0px;
    border: 1px solid #dcdcdc;
    cursor: pointer;
    background-color: #dcdcdc;
    font-size: 12px;
}

.div-footer-about{
    height: 100%;
    width: 17%;
    float: left;
    margin-top: 20px;
}

.div-footer-info{
    height: 100%;
    width: 17%;
    float: left;
    margin-top: 20px;
}

.div-footer-contact{
    height: 100%;
    width: 19%;
    float: left;
    margin-top: 20px;
}

.line_footer{
    width: 100%;
    background-color: rgb(229, 226, 226);
    height: 1px;
    overflow: hidden;

}

.div-footer-about-title{
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'Source Code Pro';
}

.div-footer-about-links{
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: black;
    font-family: 'Source Code Pro';
    text-align: center;
}

.div-footer-about-a{
    font-size: 12px;
    text-decoration: none;
    font-family: 'Source Code Pro';
}

.p_footer_developer{
    font-size: 12px;
    margin-top: 7px;
    font-family: 'Source Code Pro';
}

@media only screen and (max-width: 480px) {

    .div-login_footer{
        width: 100%;
        height: 650px;
        margin-top: auto;
    }

    .div_footer_information{
        width: 100%;
        height: 600px;
        float: left;
        overflow: hidden;
    }

    .div-footer-email{
        height: 100px;
        width: 100%;
        float: none;
        margin-top: 40px;
        margin-left: 10px;
    }

    .div-footer-about{
        height: 100px;
        width: 100%;
        float: none;
        margin-top: 5px;
        text-align: center;
    }
    
    .div-footer-info{
        height: 100px;
        width: 100%;
        float: none;
        text-align: center;
    }
    
    .div-footer-contact{
        height: 100px;
        width: 100%;
        float: none;
        text-align: center;
    }

    .p_footer_developer{
        font-size: 10px;
        margin-top: 20px;
        text-align: center;
    }

}