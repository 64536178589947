.div_pro_inf{
    width: 37%;
    height: 550px;
    float: left;
}

.info-category{
    font-size: 12px;
    margin: 0;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.b{
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 10px;
    font-size: 24px;
}

.c{
    font-family: 'Source Code Pro';
    font-weight: 300;
    font-size: 21px;
    margin-top: 7px;
}

.d{
    font-size: 10px;
    font-weight: 600;
}


.e{
    width: 250px;
    height: 30px;
}

.e div{
    float: left;
    border: 2px solid #d5cbc4;
    margin: 4px
}

.e div p{
    margin: 0;
    color: #b4aeaa;
}

.info-description-data{
    color: #646464;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: justify;
    height: 170px;
    overflow: hidden;
    overflow: scroll;
}

.info-materials-data{
    color: #646464;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: justify;
    height: 110px;
    overflow: hidden;
    overflow: scroll;
}

.informacao-line{
    width: 100%;
    height: 1px;
    background-color: #d5e8e8;
}

.ll{
    color: black;
    font-size: 16px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.photo-catalog{
    font-size: 12px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-top: 0;
}

.photo-catalog:hover{
    cursor: pointer;
}

.restock-info{
    font-size: 10px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: green;
}