.admin_users{
    min-height: 100vh;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-top: 0px;
}

.adminUserAddUser-div{
    width: 25%;
    margin: auto;
    margin-bottom: 35px;
}

.admin_users_div_button{
    width: 1170px;
    margin: auto;
    margin-bottom: 25px;

}

.admin_users_div_button button{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #FF3939;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.admin_users_div_button button:hover{
cursor: pointer;
}

.admin_users_div{
    width: 1150px;
    background-color: white;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    margin: auto;
}

.users_table{
    height: 40px;
}

.users_table_header1{
    width: 20%;
    float: left;
}

.users_table_header1 p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}

.users_table_header2{
    width: 25%;
    float: left;
}

.users_table_header2 p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}

.users_table_header3{
    width: 20%;
    float: left;
}

.users_table_header3 p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}

.users_table_header4{
    width: 20%;
    float: left;
}

.users_table_header4 p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}

.users_table_header5{
    width: 15%;
    float: left;
}

.users_table_header5 p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}