body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Roboto', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor_on_hover:hover{
  color: rgb(82, 82, 82);
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.scrolling-container {
  display: flex;
  flex-wrap: nowrap; 
  overflow: auto;
  overflow-y: hidden;
}