.div_produto1{
    width: 210px;
    height: 324px;
    float: left;
    margin-left: 17px;
    margin-bottom: 20px;
 }

.imagem_produto{
    width: 210px;
    height: 210px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.imagem_produto img{
    width: 205px;
    height: 205px;
}

.imagem_produto img:hover{
    opacity: 0.7;
}

.sku_and_categorie{
    width: 100%;
    height: 17px;
}

.div_produto-categorie{
    float: left;
    width: 20%;
    height: 100%;
    margin-top: 7px;
    font-size: 11px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 13px;
}

.div_produto-sku{
    margin-top: 7px;
    height: 100%;
    text-align: right;
    float: left;
    width: 68%;
    font-size: 13px;
    font-weight: 500;
    color: #707070;
    font-family: 'Source Code Pro';
    margin-bottom: 5px;
}

.div_produto-title{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 13px;
}

.div_produto-price{
    font-size: 13px;
    margin-top: 5px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 300;
}

.div_produto-price-notAvailable{
    margin-left: 14px;
    font-size: 13px;
    margin-top: 5px;
    color: red;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.user-div-product{
    width: 215px;
    height: 285px;
    float: left;
    margin-left: 15px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.user-div-product:hover{
    box-shadow: 0px 0px 10px 2px rgb(176, 176, 176);
}

.user-div-product:hover{
    cursor: pointer;
}