.admin-div-product{
    width: 215px;
    height: 320px;
    float: left;
    margin-left: 0px;
    margin-bottom: 0px;
    background-color: white;
    border-radius: 4px;
    position: relative;
}

.admin-div-product:hover .admin-imagem_produto img{
    cursor: pointer;
    opacity: 0.5;
}

.admin-div-product:hover{
    cursor: pointer;
}

.admin-imagem_produto{
    margin-top: 13px;
    margin-left: 13px;
    width: 190px;
    height: 190px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.admin-imagem_produto img{
    width: 190px;
    height: 190px;
    border-radius: 4px;
}

.admin-icons-edit_trash{
    width: 83px;
    height: 50px;
    margin: auto;
    margin-top: 15px;
}

.admin-icons-edit_trash-edit{
    font-size: 28px;
    margin-right: 20px;
    color: #b8afa9;
}

.admin-icons-edit_trash-trash{
    font-size: 28px;
    color: #b8afa9;
}

.admin-div_produto-title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 13px;
    width: '100%';
}

.admin-div_produto-price{
    font-size: 13px;
    margin-top: 5px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 300;
    margin-left: 13px;
    margin-bottom: 4px;
}

.admin-div_produto-categorie{
    margin-top: 7px;
    font-size: 11px;
    color: black;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 5px;
    margin-left: 13px;
}

.admin-div_produto-shopify-circle{
    width: 7px;
    height: 7px;
    background-color: green;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 9px;
    float: left;
}

.admin-div_produto-shopify{
    font-size: 11px;
    color: green;
    font-family: 'Source Code Pro';
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: 7px;
    margin-top: 0;
    display: inline-block;
}

.Shopify_synchronized_div{
    padding-top: 0;
    width: 100%;
    height: 17px;
    overflow: hidden;
}

/* nao sincronizado com shopify */
.not_admin-div_produto-shopify-circle{
    width: 7px;
    height: 7px;
    background-color: red;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 9px;
    float: left;
}

.not_admin-div_produto-shopify{
    font-size: 11px;
    color: red;
    font-family: 'Source Code Pro';
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: 7px;
    margin-top: 0;
    display: inline-block;
}