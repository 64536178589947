.admin_catalog_search{
    width: 100%;
    height: 50px;
    margin-top: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    box-shadow:inset 7px 5px 17px 3px rgb(242, 242, 242);
    color: #B5B4B7;
    font-size: 17px;
    padding-left: 25px;
    font-family: 'Helvetica Neue';
}

.admin_catalog_search:focus{
    outline: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #B5B4B7;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #B5B4B7;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #B5B4B7;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #B5B4B7;
  }

  .listIconStyle:hover{
    cursor: pointer;
  }